<template>
	<v-form>
		<v-combobox
			:label="$t('collaboration.fields.user_name')"
			persistent-hint
			ref="combo"
			:hint="$t('misc.combobox_hint', {item: 'colleague'})"
			clearable
			:disabled="progress"
			:return-object="false"
			:items="items"
			v-model="user"
		>
			<template v-slot:selection="{item}">
				{{ users[item] ? users[item].name : item }}
			</template>
		</v-combobox>
		<v-text-field
			v-model="email"
			:label="$t('misc.email')"
			:disabled="progress"
			:rules="[(v) => mwutils.validateEmail(v) || 'Invalid Email']"
			v-if="isNew"
		></v-text-field>
		<v-checkbox
			v-if="isNew && !invalidForm"
			label="Are you sure you want to add a new colleague"
			v-model="sure"
		></v-checkbox>
		<v-progress-linear
			indeterminate
			color="primary"
			v-if="progress"
		></v-progress-linear>
	</v-form>
</template>

<script>
	import firebase from "firebase";
	export default {
		name: "AddColleague",
		props: {
			value: {type: String}, // the id of the user
			clearOnEmit: {type: Boolean},
			hide: {type: Array, default: () => ([])}
		},
		data: () => {
			return {
				user: null,
				searchInput: "",
				sure: false,
				email: null,
				progress: false
			};
		},
		computed: {
			users() {
				return this.$store.getters["users/respondents"];
			},
			items() {
				const self = this;
				let items = self.mwutils.itemsArray(self.users, "name");
				return items.filter((i) => !self.hide.includes(i.value));
			},
			invalidForm() {
				return (
					this.isNew && (!this.user || !this.mwutils.validateEmail(this.email))
				);
			},
			isNew() {
				return typeof this.user == "string" && !this.users[this.user];
			}
		},
		methods: {
			async addNewUser() {
				var functions = firebase.app().functions("europe-west1");
				var addUser = functions.httpsCallable("addColleague");
				var user = {
					organisation: this.$store.state.organisation.data.id,
					name: this.user,
					email: this.email
				};
				return await addUser(user);
			},
			parseEmail(a) {
				return a.toLowerCase().trim();
			},
			clear() {
				if (this.clearOnEmit) {
					this.$refs.combo.lazyValue = "";
					this.user = null;
					this.email = "";
					this.sure = false;
				}
			}
		},
		watch: {
			sure(value) {
				if (value) {
					this.progress = true;
					this.addNewUser().then((res) => {
						this.progress = false;
						this.$store.dispatch("users/fetchById", res.data.id);
						this.$emit("input", res.data.id);
						this.clear();
					});
				}
			},
			user(value) {
				if (value) {
					if (!this.isNew) {
						this.$emit("input", value);
						this.clear();
					}
				}
			},
			email(value) {
				this.email = this.parseEmail(value);
			}
		},
		created() {
			if (this.value) {
				this.user = this.users[this.value];
			}
		}
	};
</script>
